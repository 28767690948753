<template>
  <validation-observer
    ref="programmeSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="programmeSaveModal"
      body-class="position-static"
      size="lg"
      centered
      :title="isCreated ? `Thêm ${resourceName.fullName}` : `Cập nhật ${resourceName.fullName}`"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group label-for="trainingSystemId">
              <template v-slot:label>
                Hệ đào tạo <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Hệ đào tạo"
                rules="required"
              >
                <v-select
                  :disabled="!isCreated"
                  v-model="targetProgramme.trainingSystemId"
                  :options="trainingSystems"
                  :reduce="option => option.value"
                  @input="onTrainingSystemsChange"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="courseId">
              <template v-slot:label>
                Khóa học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Khóa học"
                rules="required"
              >
                <v-select
                  :disabled="!isCreated"
                  v-model="targetProgramme.courseId"
                  :options="courses"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label-for="departmentId">
          <template v-slot:label>
            Khoa/Phòng/Bộ môn <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Khoa/Phòng/Bộ môn"
            rules="required"
          >
            <v-select
              :disabled="!isCreated"
              v-model="targetProgramme.departmentId"
              :options="departments"
              :reduce="option => option.value"
              @input="onDepartmentChange($event)"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="majorId">
          <template v-slot:label>
            Ngành học <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Ngành học"
            rules="required"
          >
            <v-select
              :disabled="!isCreated"
              v-model="targetProgramme.majorId"
              :options="majors"
              :reduce="option => option.value"
              @input="onMajorChange"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="name">
          <template v-slot:label>
            Tên {{ resourceName.fullName }} <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            :name="`Tên ${resourceName.fullName}`"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="targetProgramme.name"
              name="name"
              :placeholder="`Nhập tên ${resourceName.fullName}`"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="code">
          <template v-slot:label>
            Mã {{ resourceName.fullName }} <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            :name="`Mã ${resourceName.fullName}`"
            rules="required"
          >
            <b-form-input
              id="code"
              v-model="targetProgramme.code"
              name="code"
              :placeholder="`Nhập mã ${resourceName.fullName}`"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group label-for="avgCreditNum">
              <template v-slot:label>
                Số tín chỉ TB năm học(M) <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số tín chỉ TB năm học(M)"
                rules="required"
              >
                <b-form-input
                  id="avgCreditNum"
                  v-model="targetProgramme.avgCreditNum"
                  name="avgCreditNum"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetProgramme.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group label-for="orderNo">
              <template v-slot:label>
                Thứ tự <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Thứ tự"
              >
                <b-form-input
                  id="orderNo"
                  v-model="targetProgramme.orderNo"
                  name="orderNo"
                  placeholder="Nhập thứ tự"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('programmeSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isProgrammeSaveLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BModal,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'

export default {
  name: 'ProgrammeSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    programme: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isProgrammeSaveLoading: false,
      targetProgramme: {
        name: '',
        code: '',
        status: 1,
        orderNo: 1,
        avgCreditNum: '',
        courseId: 0,
        majorId: 0,
        departmentId: 0,
      },
      required,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      statuses: 'programme/statuses',
      resourceName: 'programme/resourceName',
      maxOrderNo: 'programme/maxOrderNo',
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      courseYears: 'dropdown/courseYears',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      majorCodes: 'dropdown/majorCodes',
      avgCreditNum: 'parameter/avgCreditNum',
    }),
    statusOptions() {
      return this.statuses.map(item => ({ value: item.value, label: item.label }))
    },
    isCreated() {
      return !this.programme
    },
    getOrganizationId() {
      const cUser = getUser()
      return cUser.orgId ? cUser.orgId : 0
    },
  },
  methods: {
    ...mapActions({
      createProgramme: 'programme/createProgramme',
      updateProgramme: 'programme/updateProgramme',
      getMaxOrderNo: 'programme/getMaxOrderNo',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getAvgCreditNum: 'parameter/getAvgCreditNum',
    }),
    async onTrainingSystemsChange(event) {
      this.targetProgramme.courseId = null
      await this.getCourses({ trainingSystemId: event })
    },
    async onDepartmentChange(event) {
      this.targetProgramme.majorId = null
      await this.getMajors({ departmentId: event })
    },
    async onMajorChange(event) {
      this.targetProgramme.name = null
      this.targetProgramme.code = null
      // Lay thong  tin nganh + nam hoc de tao ten + ma CTDT
      const courseYear = this.courseYears.find(e => e.value === this.targetProgramme.courseId).label
      const majorName = this.majors.find(e => e.value === event).label
      const majorCode = this.majorCodes.find(e => e.value === event).label
      this.targetProgramme.name = `${majorName} (${courseYear})`
      this.targetProgramme.code = `${courseYear}.${majorCode}`
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isProgrammeSaveLoading = true
      if (this.programme) {
        this.targetProgramme = { ...this.programme }
      }
      const cUser = getUser()
      const userOrgId = cUser.orgId ? cUser.orgId : 0

      if (this.isCreated) {
        await Promise.all([
          this.getTrainingSystems({ organizationId: this.getOrganizationId }),
          // this.getCourses(),
          this.getDepartments({ organizationId: userOrgId }),
          // this.getMajors(),
          this.getMaxOrderNo(),
          this.getAvgCreditNum('AVG_CREDIT_NUM'),
        ])
        this.targetProgramme.orderNo = this.maxOrderNo + 1
        this.targetProgramme.avgCreditNum = this.avgCreditNum
      } else {
        await Promise.all([
          this.getTrainingSystems({ organizationId: this.getOrganizationId }),
          this.getCourses(),
          this.getDepartments({ organizationId: userOrgId }),
          this.getMajors(),
        ])
      }
      this.isProgrammeSaveLoading = false
    },
    onHide() {
      this.$refs
        .programmeSaveFormRef
        .reset()
      this.targetProgramme = {
        name: '',
        code: '',
        status: 1,
        orderNo: 1,
        avgCreditNum: 1,
        courseId: 0,
        majorId: 0,
        departmentId: 0,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .programmeSaveFormRef
        .validate()
      if (valid) {
        this.isProgrammeSaveLoading = true
        try {
          const response = this.isCreated
            ? await this.createProgramme(this.targetProgramme)
            : await this.updateProgramme(this.targetProgramme)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('programmeSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isProgrammeSaveLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
