var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"programmeCopyFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{attrs:{"id":"programmeCopyModal","body-class":"position-static","centered":"","title":("Sao chép " + (_vm.resourceName.fullName)),"no-close-on-backdrop":""},on:{"show":_vm.onShow,"hide":_vm.onHide},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-content-end"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return _vm.onSave('hide')}}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v(" Sao chép ")],1)]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$bvModal.hide('programmeCopyModal')}}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}}),_vm._v(" Hủy ")],1)])],1)]},proxy:true}],null,true)},[_c('b-form',[_c('b-form-group',{attrs:{"label-for":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tên "+_vm._s(_vm.resourceName.fullName)+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":("Tên " + (_vm.resourceName.fullName)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","placeholder":("Nhập tên " + (_vm.resourceName.fullName)),"state":_vm.getElementState(errors)},model:{value:(_vm.targetData.name),callback:function ($$v) {_vm.$set(_vm.targetData, "name", $$v)},expression:"targetData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"code"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Mã "+_vm._s(_vm.resourceName.fullName)+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":("Mã " + (_vm.resourceName.fullName)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"code","name":"code","placeholder":("Nhập mã " + (_vm.resourceName.fullName)),"state":_vm.getElementState(errors)},model:{value:(_vm.targetData.code),callback:function ($$v) {_vm.$set(_vm.targetData, "code", $$v)},expression:"targetData.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"avgCreditNum"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Số tín chỉ tối thiểu "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Số tín chỉ tối thiểu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"avgCreditNum","name":"avgCreditNum","state":_vm.getElementState(errors)},model:{value:(_vm.targetData.avgCreditNum),callback:function ($$v) {_vm.$set(_vm.targetData, "avgCreditNum", $$v)},expression:"targetData.avgCreditNum"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"trainingSystemId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Hệ đào tạo "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Hệ đào tạo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.trainingSystems,"reduce":function (option) { return option.value; }},on:{"input":function($event){return _vm.onTrainingSystemsChange($event)}},model:{value:(_vm.targetData.trainingSystemId),callback:function ($$v) {_vm.$set(_vm.targetData, "trainingSystemId", $$v)},expression:"targetData.trainingSystemId"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1),_c('b-form-group',{attrs:{"label-for":"courseId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Khóa học "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Khóa học","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.courses,"reduce":function (option) { return option.value; }},model:{value:(_vm.targetData.courseId),callback:function ($$v) {_vm.$set(_vm.targetData, "courseId", $$v)},expression:"targetData.courseId"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"departmentId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Khoa/Phòng ban/bộ môn "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Khoa/Phòng ban/bộ môn","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.departments,"reduce":function (option) { return option.value; }},on:{"input":function($event){return _vm.onDepartmentChange($event)}},model:{value:(_vm.targetData.departmentId),callback:function ($$v) {_vm.$set(_vm.targetData, "departmentId", $$v)},expression:"targetData.departmentId"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"majorId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Ngành học "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Ngành học","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.majors,"reduce":function (option) { return option.value; }},on:{"input":function($event){return _vm.onMajorChange()}},model:{value:(_vm.targetData.majorId),callback:function ($$v) {_vm.$set(_vm.targetData, "majorId", $$v)},expression:"targetData.majorId"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"status"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Trạng thái "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Trạng thái","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.statusOptions,"reduce":function (option) { return option.value; }},model:{value:(_vm.targetData.status),callback:function ($$v) {_vm.$set(_vm.targetData, "status", $$v)},expression:"targetData.status"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Thứ tự","label-for":"orderNo"}},[_c('b-form-input',{attrs:{"id":"orderNo","name":"orderNo","placeholder":"Nhập thứ tự"},model:{value:(_vm.targetData.orderNo),callback:function ($$v) {_vm.$set(_vm.targetData, "orderNo", $$v)},expression:"targetData.orderNo"}})],1)],1)],1)],1),_c('b-overlay',{attrs:{"no-wrap":"","variant":"white","spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm","show":_vm.isLoading}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }