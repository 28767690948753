<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body class="mb-md-0">
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="mx-2 my-1">
            <b-row>
              <b-col cols="8" md="8"><h3>{{modalTitle}}</h3></b-col>
              <b-col cols="4">
                <div class="text-right">
                  <b-button
                    v-if="creatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.programmeSubjectGroupSaveModal
                    variant="success"
                    size="sm"
                    class="ml-1"
                    @click="onCreateMainData"
                  >
                    <span class="text-nowrap text-right d-none d-lg-block">
                      <feather-icon icon="PlusSquareIcon" /> Thêm nhóm
                    </span>
                    <span class="d-block d-lg-none"> <feather-icon icon="PlusSquareIcon" /></span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col cols="12">
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  row-style-class="vgt-row"
                  styleClass="vgt-table striped bordered"
                  max-height="260px"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div slot="emptystate" style="text-align: center; font-weight: bold">
                    Không có bản ghi nào !
                  </div>
                  <template slot="table-row" slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'"
                          :class="props.row.status?'text-success':'text-danger'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else-if="props.column.field === 'action'" class="thead-group-action">
                      <b-button-group>
                        <b-button
                          v-if="updatable"
                          v-b-modal.programmeSubjectGroupSaveModal
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          title="Chỉnh sửa"
                          @click="onEditMainData(props.row)"
                        >
                          <feather-icon icon="Edit2Icon" />
                        </b-button>
                        <b-button
                          v-if="creatable"
                          variant="danger"
                          class="btn-icon"
                          size="sm"
                          title="Xóa"
                          @click="onDeleteMainData(props.row)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </b-button-group>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                          đến {{filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <ProgrammeSubjectGroupSave
      ref="programmeSubjectGroupSaveFormRef"
      :main-data="currentMainData"
      :ext-data="extMainData"
      @succeed="onSucceed"
    />
  </div>
</template>

<script>
import {
  BButton, BButtonGroup, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import ProgrammeSubjectGroupSave from '@/views/programme-subject-group/ProgrammeSubjectGroupSave.vue'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export const Attr = Object.freeze({
  store: 'programmeSubjectGroup',
})
export default {
  name: 'ProgrammeSubjectGroup',
  directives: {
    Ripple,
  },
  components: {
    ProgrammeSubjectGroupSave,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  props: {
    dataSend: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 100,
        programmeId: this.dataSend.id || this.$route.params.id,
      },
      modalTitle: `Danh sách Nhóm môn học của CTĐT - [${this.dataSend.code}] ${this.dataSend.name}`,
      currentMainData: undefined,
      extMainData: {
        parentId: this.dataSend.id || this.$route.params.id,
        courseId: this.dataSend.courseId,
      },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [5, 10, 20, 30, 50, 80, 100],
    }
  },
  computed: {
    ...mapGetters({
      mainData: `${Attr.store}/dataLists`,
      totalRows: `${Attr.store}/totalRows`,
      statuses: `${Attr.store}/statuses`,
      resourceName: `${Attr.store}/resourceName`,
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    columns() {
      return [
        {
          label: '#',
          field: 'rowNum',
          width: '30px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: `Tên ${this.resourceName.shortName}`,
          field: 'name',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: `Mã ${this.resourceName.shortName}`,
          field: 'code',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Học kỳ đăng ký(dự kiến)',
          field: 'courseSemesterName',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Số TC tổi thiểu',
          field: 'minCreditNum',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
            filterDropdownItems: this.statusFilterDropdownItems,
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        // {
        //   label: 'Số TT',
        //   field: 'orderNo',
        //   sortable: true,
        //   thClass: 'text-center',
        //   tdClass: 'text-center',
        // },
        {
          label: '',
          field: 'action',
          width: '80px',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ]
    },
    // Permission
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.PROGRAMME_SUBJECT_GROUPS)
    },
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.PROGRAMME_SUBJECT_GROUPS)
    },
    deletable() {
      return hasPermissionForResource(PermissionCode.DELETE, ResourceCode.PROGRAMME_SUBJECT_GROUPS)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.getMainData(this.filter)
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: `${Attr.store}/getData`,
      deleteMainData: `${Attr.store}/deleteData`,
    }),
    onCreateMainData() {
      this.currentMainData = undefined
      this.extMainData = {
        parentId: this.dataSend.id || this.$route.params.id,
        courseId: this.dataSend.courseId,
      }
    },
    onEditMainData(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onDeleteMainData(mainData) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa ${this.resourceName.fullName} <span class="text-danger">${mainData.name}</span> khỏi CTĐT?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteMainData(mainData.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getMainData(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: param.currentPage, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getMainDataFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.getMainDataFromStore()
    },
  },
}
</script>
